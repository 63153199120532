import React from 'react';

function Icon({className}) {
    return (<svg className={className} width={'24'} height={'24'} viewBox={'0 0 64 64'} fill={"none"} xmlns={"http://www.w3.org/2000/svg"}>
<circle cx="32" cy="32" r="32" fill="#0657F9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.6503 12.6038L16.1257 15.1706L22.2818 21.4274L28.4378 27.6842V33.5107V39.3371H32.0135H35.5893V33.511V27.6848L41.7456 21.4271L47.9018 15.1694L45.358 12.5847L42.8142 10L37.4506 15.4513C34.5006 18.4496 32.0537 20.9027 32.0132 20.9027C31.9726 20.9027 29.5343 18.4579 26.5947 15.4698C23.655 12.4817 21.2331 10.0369 21.2124 10.0369C21.1918 10.0369 20.0389 11.1919 18.6503 12.6038ZM16.0058 27.2092C15.1103 28.7856 14.285 31.5919 14.0576 33.8342C13.9453 34.94 14.0047 37.3535 14.1707 38.4379C15.7241 48.5837 25.1531 55.4801 35.0756 53.7278C41.617 52.5727 46.9959 47.8823 49.0868 41.5103C49.7748 39.4134 49.9978 37.9952 50 35.7027C50.0023 33.2213 49.7243 31.5721 48.9197 29.2956C48.6439 28.5149 47.9343 26.9726 47.851 26.9726C47.8176 26.9726 46.5834 28.1995 45.1085 29.6992C42.6284 32.2206 42.4294 32.4419 42.4638 32.6404C42.4843 32.7585 42.5551 33.1022 42.6211 33.4041C42.8129 34.2817 42.8877 35.4693 42.8137 36.4592C42.7357 37.4995 42.6127 38.1406 42.3014 39.1282C41.3021 42.2991 38.6553 45.0151 35.4945 46.113C34.2873 46.5323 33.6097 46.6358 32.0504 46.6391C30.4654 46.6426 29.8676 46.557 28.6391 46.1508C25.297 45.046 22.5734 42.1951 21.5925 38.7751C21.274 37.6643 21.1824 36.9547 21.1865 35.6278C21.1903 34.3942 21.2291 34.0784 21.5545 32.6303C21.5807 32.5135 20.9885 31.8717 18.8681 29.7196L16.1481 26.9588L16.0058 27.2092Z" fill="white"/>
</svg>);
}

export default Icon;
